import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

gsap.registerPlugin(ScrollTrigger);

export default el => {

    const $el = $(el);
    const image = $el.find('[data-image]').get(0);
    const imageWrap = image.parentNode;

    let tween = null;
    let currentWidth = 0;

    const killTween = () => {
        if (tween) {
            const trigger = tween.scrollTrigger;
            if (trigger) {
                trigger.kill(true);
            }
            tween.pause(0).kill(true);
        }
        gsap.set([image, imageWrap].concat($(image).find('img').get()), { clearProps: 'all' });
    };

    const createTween = () => {
        tween = gsap.fromTo(image, {
            x: 0
        }, {
            x: () => (image.scrollWidth - window.innerWidth) * -1,
            ease: 'Power1.easeInOut',
            scrollTrigger: {
                trigger: image,
                pin: true,
                scrub: 0.9,
                start: 'center center',
                end: () => `+=${image.offsetWidth}`
            }
        });
    };

    const onResize = (force = false) => {
        const { width: newWidth } = Viewport;
        if (newWidth === currentWidth) {
            return;
        }
        currentWidth = newWidth;
        killTween();
        const { width: imageWidth } = image.getBoundingClientRect();
        if (imageWidth < (currentWidth * 1.25)) {
            gsap.set([imageWrap, image], { width: Viewport.width });
            gsap.set($(image).find('img').get(), { width: '100%', height: 'auto' });
            return;
        }
        createTween();
    };

    const init = () => {
        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
