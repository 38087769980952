/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.COMPONENT_INIT, 'This ES6 shit is wack');
*
 */

//export const COMPONENT_INIT = 'COMPONENT_INIT';
export const MENU_OPEN = 'MENU_OPEN';
export const MENU_CLOSE = 'MENU_CLOSE';
export const SCROLL_START = 'SCROLL_START';
export const SCROLL_END = 'SCROLL_END';
