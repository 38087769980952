import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';
import Viewport from '../core/Viewport';
import $ from '../core/Dom';
import Lottie from './Lottie';

gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

let prevBug = null;

export default (el, props) => {

    if (el.offsetParent === null) {
        return;
    }

    const {bugs} = window;
    if (!bugs) {
        return;
    }

    const $el = $(el);
    const path = $el.find('svg [id$="path"] path').get(0);
    const pathRect = $el.find('svg [id$="path"] rect').get(0);
    const bug = $el.find('[data-bug]').get(0);
    const clipRect = $el.find('svg [id$="mask"] rect').get(0);
    const {scrollTrigger: scrollTriggerOpts, motionPath: motionPathOpts} = props || {};

    const bugIds = Object.keys(bugs);
    let bugId = props.bug || null;

    if (!bugId) {
        while (bugId === null || bugId === prevBug) {
            bugId = bugIds[Math.floor(Math.random() * bugIds.length)];
        }
    }

    prevBug = bugId;

    $el.addClass(`bug--${bugId}`);

    const lottieUrl = bugs[bugId].json;
    const lottie = Lottie($el.find('[data-lottie]').get(0), {path: lottieUrl});
    const imageUrl = bugs[bugId].svg;
    const $image = $el.find('[data-image]').eq(0);
    $image.html(`<img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="${imageUrl}" class="absolute full lazyload" alt="" role="presentation" />`);

    let tl;

    const killTl = () => {
        if (!tl) {
            return;
        }
        if (tl.scrollTrigger) {
            tl.scrollTrigger.kill(true);
        }
        tl.pause(0).invalidate().kill();
        tl = null;
        gsap.set(bug, { clearProps: 'transform' });
    };

    const createTl = () => {
        killTl();
        const clipRectSetter = gsap.quickSetter(clipRect, 'attr');
        tl = gsap.timeline({
            paused: true,
            scrollTrigger: {
                trigger: el,
                scrub: true,
                start: 'top center',
                end: 'bottom center',
                ...(scrollTriggerOpts || {}),
                onUpdate() {
                    // Update the clip-path rect transform
                    const transform = pathRect.getAttribute('transform');
                    const origin = pathRect.getAttribute('data-svg-origin');
                    clipRectSetter({transform, origin});
                }
            }
        })
            .fromTo([path, bug], {opacity: 0}, {opacity: 1, duration: 0.001}, 0)
            .to(bug, {
                ease: 'none',
                duration: 1,
                motionPath: {
                    path,
                    align: path,
                    autoRotate: true,
                    alignOrigin: [0.25, 0.5],
                    start: 0,
                    end: 1,
                    ...(motionPathOpts || {})
                }
            }, 0)
            .to(pathRect, {
                ease: 'none',
                duration: 1,
                motionPath: {
                    path,
                    align: path,
                    autoRotate: true,
                    alignOrigin: [1, 0.5],
                    start: 0,
                    end: 1
                }
            }, 0);
    };

    const onResize = () => {
        killTl();
        createTl();
    };

    const init = () => {
        createTl();
        lottie.init();
        gsap.to(el, {opacity: 1, duration: 0.3, delay: 0.5});
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        killTl();
        lottie.destroy();
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };

};
