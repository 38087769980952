import $ from '../core/Dom';
import superagent from '../core/request';
import Modal from '../lib/Modal';

export default el => {

    const $el = $(el);

    const { uid, siteId } = el.dataset;

    let modal;
    let request;

    const showModal = html => {
        const modalEl = $(html).get(0);
        $('body').append(modalEl);
        modal = Modal(modalEl);
        modal.show();
    };

    const onClick = () => {
        if (modal) {
            modal.show();
            return;
        }
        if (request) {
            return;
        }
        request = superagent.get(`/modals/species/${uid}?siteId=${siteId}`);
        request
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                showModal(text);
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                request = null;
            });
    };

    const init = () => {
        $el.on('click', onClick);
    };

    const destroy = () => {
        $el.off('click');
        if (modal) {
            modal.destroy();
        }
    };

    return {
        init,
        destroy
    };

};
