import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import Dispatch from '../core/Dispatch';
import { SCROLL_START, SCROLL_END } from './events';

gsap.registerPlugin(ScrollToPlugin);

/**
 *
 * @param to
 * @param options
 */
export const scrollTo = (to, options = {}) => {
    Dispatch.emit(SCROLL_START);
    gsap.to(window, {
        scrollTo: {
            y: to
        },
        duration: 0.5,
        ease: 'Quad.easeInOut',
        onComplete: () => {
            requestAnimationFrame(() => {
                console.log('scroll end');
                Dispatch.emit(SCROLL_END);
            });
        },
        ...options
    });
};

/**
 *
 * @param px
 * @returns {number}
 */
export const pxToNumber = px => parseFloat(px.replace('px', ''));


/**
 *
 * @param node
 * @param eventName
 * @param eventOpts
 */
export const dispatchEvent = (node, eventName, eventOpts) => {
    node.dispatchEvent(new CustomEvent(eventName, {
        bubbles: true,
        cancelable: true,
        ...(eventOpts || {})
    }));
};