import serialize from 'form-serialize';
import gsap from 'gsap';
import $ from '../core/Dom';
import superagent from '../core/request';
// import Dispatch from '../core/Dispatch';
// import { COMPONENT_INIT } from '../lib/events';

export default (form, props) => {

    let isSubmitting = false;

    const { successMessage, errorMessage } = props;
    const email = $(form).find('input[type="email"]');

    const showError = () => {
        $(form).find('[data-response]').text(errorMessage).focus();
    };

    const showReceipt = () => {
        const value = email.val();
        if (typeof window.vgo === 'function' && !!value) {
            window.vgo('setEmail', value);
            window.vgo('process');
        }
        form.reset();
        $(form).find('[data-response]').text(successMessage).focus();
    };

    const onSubmit = e => {
        e.preventDefault();
        if (isSubmitting) {
            return;
        }
        $(form).find('[data-response]').text('');
        const $submit = $(form).find('button[type="submit"]');
        const spinner = $submit.find('[data-spinner]').get(0);
        const label = $submit.find('span:not([data-spinner])').get(0);
        spinner.hidden = false;
        gsap.timeline()
            .fromTo(spinner, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
            .to(label, { opacity: 0, duration: 0.3 }, 0);
        const request = superagent.post(window.location.href);
        request
            .accept('application/json')
            .send(serialize(form))
            .then(({ status, body }) => {
                if (status !== 200 || !body.success) {
                    showError();
                } else {
                    showReceipt();
                }
            })
            .catch(error => {
                console.error(error);
                showError();
            })
            .then(() => {
                gsap.timeline()
                    .to(spinner, { opacity: 0, duration: 0.3 }, 0)
                    .to(label, { opacity: 1, duration: 0.3 }, 0)
                    .add(() => {
                        spinner.hidden = true;
                    });
                isSubmitting = false;
            });
    };

    const init = () => {
        $(form).on('submit', onSubmit);
    };

    const destroy = () => {
        $(form).off('submit');
    };

    return {
        init,
        destroy
    };

};
