import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { MENU_CLOSE, MENU_OPEN, SCROLL_END, SCROLL_START } from '../lib/events';// COMPONENT_INIT,

export default el => {

    const $el = $(el);

    let prevScrollTop = null;
    let preventHide = false;
    let viewportHeight;
    let heroHeight;
    let maxScroll;

    const hide = () => {
        $el.addClass('is-hidden');
    };

    const show = () => {
        $el.removeClass('is-hidden');
    };

    const onScroll = () => {
        const { scrollTop } = Viewport;
        if (prevScrollTop !== null && Math.abs(scrollTop - prevScrollTop) < 5) {
            return;
        }
        const direction = prevScrollTop !== null && prevScrollTop < scrollTop && scrollTop > 0 ? 'down' : 'up';
        if (direction === 'down' && !preventHide && maxScroll >= viewportHeight * 1.5 && scrollTop > 100) {
            hide();
        } else {
            show();
        }
        if (!heroHeight || scrollTop >= (heroHeight * 0.85)) {
            $el.removeClass('is-transparent');
        } else {
            $el.addClass('is-transparent');
        }
        prevScrollTop = scrollTop;
    };

    const onResize = () => {
        viewportHeight = Viewport.height;
        maxScroll = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
        const $hero = $('.hero');
        heroHeight = $hero.length ? $hero.height() : null;
        onScroll();
    };

    const onMenuOpen = () => {
        $el.addClass('js-menu-open');
    };

    const onMenuClose = () => {
        $el.removeClass('js-menu-open');
    };

    const onScrollStart = () => {
        preventHide = true;
    };

    const onScrollEnd = () => {
        requestAnimationFrame(() => {
            preventHide = false;
        });
    };

    const init = () => {
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
        Dispatch.on(MENU_OPEN, onMenuOpen);
        Dispatch.on(MENU_CLOSE, onMenuClose);
        Dispatch.on(SCROLL_START, onScrollStart);
        Dispatch.on(SCROLL_END, onScrollEnd);
        onResize();
        //Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
        Dispatch.off(MENU_OPEN, onMenuOpen);
        Dispatch.off(MENU_CLOSE, onMenuClose);
        Dispatch.off(SCROLL_START, onScrollStart);
        Dispatch.off(SCROLL_END, onScrollEnd);
    };

    return {
        init,
        destroy
    };

};
