import $ from '../core/Dom';
//import Dispatch from '../core/Dispatch';
import { pxToNumber, scrollTo } from '../lib/helpers';
//import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const $el = $(el);

    const onClick = e => {
        e.preventDefault();
        const target = $($el.attr('href')).get(0);
        if (!target) {
            return;
        }
        const { top: offset } = $(target).offset();
        const scrollTopMargin = pxToNumber(getComputedStyle(target).scrollMarginTop || '0');
        scrollTo(offset - scrollTopMargin, {
            duration: 1,
            ease: 'Power2.easeInOut'
        });
    };

    const init = () => {
        $el.on('click', onClick);
        //Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        $el.off('click', onClick);
    };

    return {
        init,
        destroy
    };

};
