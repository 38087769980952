import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import gsap from 'gsap';

import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';
// import Revealer from './lib/Revealer';
// import Dispatch from './core/Dispatch';
// import { COMPONENT_INIT } from './lib/events';

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    lazySizes.init();

    $('body').addClass('js-init');

    //Revealer.init();

    // requestAnimationFrame(() => {
    //     Dispatch.on(COMPONENT_INIT, () => {
    //         Revealer.update();
    //     });
    // });
};

require('doc-ready')(init);
