import gsap from 'gsap';
import $ from '../core/Dom';
import Components from '../core/Components';
import Viewport from '../core/Viewport';

const Modals = new WeakMap();

export default el => {

    let modal = Modals.get(el);

    if (modal) {
        return modal;
    }

    modal = {
        isHidden: true,
        init() {
            this.el = el;
            this.content = $(el).find('[data-modal-content]').get(0);
            this.overlay = $(el).find('[data-modal-overlay]').get(0);
            this.show = this.show.bind(this);
            this.hide = this.hide.bind(this);
            this.destroy = this.destroy.bind(this);
            this.onKeyUp = this.onKeyUp.bind(this);
            this.onBodyClick = this.onBodyClick.bind(this);
            $('body')
                .on('keyup', this.onKeyUp)
                .on('click', this.onBodyClick);
            Components.init(el);
        },
        show() {
            if (!this.isHidden) {
                return;
            }
            gsap.killTweensOf([this.overlay, this.content]);
            requestAnimationFrame(() => {
                this.isHidden = false;
                this.el.hidden = false;
                this.focusedElement = document.activeElement || null;
                Viewport.lockTabbing(this.el, $(this.el).find('[data-modal-closebtn]').get(0));
                gsap.timeline({
                    onComplete: () => {
                        gsap.set([this.overlay, this.content], { clearProps: 'all' });
                    }
                })
                    .fromTo(this.overlay, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
                    .fromTo(this.content, { opacity: 0 }, { opacity: 1, duration: 0.3, ease: 'Cubic.easeInOut' }, 0)
                    .fromTo(this.content.parentNode, { y: 30 }, { y: 0, duration: 0.5, ease: 'Power2.easeOut' }, 0);
            });
        },
        hide() {
            if (this.isHidden) {
                return;
            }
            this.isHidden = true;
            const afterHide = () => {
                this.el.hidden = true;
                Viewport.releaseTabbing(this.focusedElement);
                gsap.set([this.overlay, this.content], { clearProps: 'all' });
            };
            gsap.killTweensOf([this.overlay, this.content]);
            gsap.timeline({
                onComplete: afterHide
            })
                .to(this.overlay, { opacity: 0, duration: 0.3 }, 0)
                .to(this.content, { opacity: 0, duration: 0.3, ease: 'Cubic.easeInOut' }, 0)
                .to(this.content.parentNode, { y: 30, duration: 0.3, ease: 'Quad.easeIn' }, 0);
        },
        destroy() {
            this.hide();
            $('body')
                .off('keyup', this.onKeyUp)
                .off('click', this.onBodyClick);
            Components.destroy(el);
            Modals.delete(el);
        },
        onKeyUp(e) {
            if (this.isHidden) {
                return;
            }
            const key = e.key || e.which || e.keyCode || null;
            if (['Escape', 27].indexOf(key) > -1) {
                this.hide();
            }
        },
        onBodyClick(e) {
            if (this.isHidden) {
                return;
            }
            if (e.target.hasAttribute('data-modal-closebtn') || $(e.target).parent('[data-modal-closebtn]').get(0)) {
                this.hide();
                return;
            }
            const contentEl = this.content.firstElementChild;
            if (e.target === contentEl || contentEl.contains(e.target)) {
                return;
            }
            this.hide();
        }
    };

    modal.init();

    Modals.set(el, modal);

    return modal;
};
