import gsap from 'gsap';
//import Dispatch from '../core/Dispatch';
import Viewport from "../core/Viewport";
import { loadLottie } from "../lib/async-bundles";
import { dispatchEvent } from '../lib/helpers';
//import { COMPONENT_INIT } from '../lib/events';

export default (el, props) => {

    const lottieOpts = {
        container: el,
        renderer: 'canvas',
        loop: true,
        autoplay: true,
        scaleMode: 'noScale',
        ...(props || {})
    };

    let observer;
    let lottie;
    let introTween;
    let lottieInited = false;
    let animationLoaded = false;

    const onLottieLoaded = () => {
        animationLoaded = true;
        lottie.removeEventListener('data_ready', onLottieLoaded);
        requestAnimationFrame(() => {
            lottie.resize();
            introTween.play();
            dispatchEvent(el, 'lottie_ready', { detail: { lottie } });
        });
    };

    const initLottie = () => {
        if (lottieInited) {
            return;
        }
        lottieInited = true;
        loadLottie(Lottie => {
            lottie = Lottie.loadAnimation(lottieOpts);
            lottie.addEventListener('data_ready', onLottieLoaded);
        });
    };

    const onObserve = entries => {
        const { isIntersecting } = entries[0];
        if (isIntersecting) {
            initLottie();
        }
    };

    const onResize = () => {
        if (!animationLoaded || lottieOpts.renderer !== 'canvas') {
            return;
        }
        lottie.resize();
    };

    const init = () => {
        introTween = gsap.timeline({ paused: true }).fromTo(el, { opacity: 0 }, { opacity: 1, duration: 0.5, ease: 'Quad.easeIn' });
        observer = new IntersectionObserver(onObserve);
        observer.observe(el);
        Viewport.on('resize', onResize);
        //Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        observer.disconnect();
        observer = null;
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };

};
