import Flickity from 'flickity';
import gsap from 'gsap';
import $ from '../core/Dom';
//import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';

//import { COMPONENT_INIT } from '../lib/events';

export default (el, props) => {

    const $el = $(el);
    const slider = $el.find('[data-slider]').get(0);
    const slides = $el.find('[data-slide]').get();
    const numSlides = slides.length;
    const btns = $el.find('[data-btn]').get();

    const { options: flickityOptions = {} } = props || {};

    let flkty;

    const destroyFlickity = () => {
        if (!flkty) {
            return;
        }
        //$(slider).off('focusin'); TODO
        flkty.destroy();
        flkty = null;
    };

    const createFlickity = () => {
        destroyFlickity();
        flkty = new Flickity(slider, {
            contain: false,
            dragThreshold: 15,
            cellAlign: 'left',
            prevNextButtons: false,
            pageDots: false,
            freeScroll: false,
            freeScrollFriction: 0.045,
            setGallerySize: false,
            percentPosition: false,
            resize: true,
            wrapAround: false,
            accessibility: false,
            ...flickityOptions,
            on: {
                dragStart() {
                    document.ontouchmove = e => e.preventDefault();
                },
                dragEnd() {
                    document.ontouchmove = () => true;
                },
                select() {
                    if (!btns.length) {
                        return;
                    }
                    if (!this.selectedIndex) {
                        gsap.set($el.find('[data-btn="prev"]').get(0), { opacity: 0.3, cursor: 'default' });
                    } else {
                        gsap.set($el.find('[data-btn="prev"]').get(0), { clearProps: 'all' });
                    }
                    if (this.selectedIndex >= numSlides - 1) {
                        gsap.set($el.find('[data-btn="next"]').get(0), { opacity: 0.3, cursor: 'default' });
                    } else {
                        gsap.set($el.find('[data-btn="next"]').get(0), { clearProps: 'all' });
                    }
                }
            }
        });

        // TODO
        // $(slider).on('focusin', e => {
        //     const slide = $(e.delegateTarget);
        //     flkty.select(Number(slide.data('slide')), true, true);
        // });
    };

    const maybeShowNextPrev = () => {
        btns.forEach(btn => { btn.hidden = !flkty || !flkty.isDraggable; });
    };

    const onNextPrevClick = e => {
        if (!flkty) {
            return;
        }
        const { btn: direction } = e.triggerTarget.dataset;
        if (direction === 'next') {
            flkty.next();
        } else {
            flkty.previous();
        }
    };

    const onResize = () => {
        requestAnimationFrame(() => {
            maybeShowNextPrev();
        });
    };

    const init = () => {
        createFlickity();
        Viewport.on('resize', onResize);
        onResize();
        $el.on('click', '[data-btn]', onNextPrevClick);
        //Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        destroyFlickity();
        $el.off('click');
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };

};
