/*
*   Got an expensive thing that (potentially) gets called _a lot_?
*   deferredCallback accepts a callback function and an FPS count, puts the callback behind a RAF call and makes sure it doesn't get called too often.
*   Think of it as requestAnimationFrame w/ an FPS throttle!
*
*   Usage:
*
*   import {deferredCallback} from '@vaersaagod/tools/utils';
*
*   deferredCallback(myExpensiveFunction, 30);
*
 */
const deferredCallbackScope = {};
export const deferredCallback = (cb, fps = 60, ...args) => (...args) => requestAnimationFrame(() => {

    const now = new Date().getTime();
    const interval = 1000 / fps;

    deferredCallbackScope[cb] = deferredCallbackScope[cb] || null;

    const delta = deferredCallbackScope[cb] ? now - deferredCallbackScope[cb] : null;

    if (delta === null || delta > interval) {
        deferredCallbackScope[cb] = now - (delta % interval);
        cb(...args);
    }

});

/*
*   Measures the viewport's scrollbar width
*   Useful for dodgy code that prevents the screen from "jumping" when scrolling is locked
*
 */
let scrollbarWidth = null;
export const getScrollbarWidth = () => {

    if (scrollbarWidth === null) {

        // Force vertical scrollbar
        const body = document.body;
        const oldHeight = body.style.height || '';
        const oldOverflow = body.style.overflow || '';
        const scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
        body.style.height = '200%';

        // Measure body width
        const bodyWidth = body.getBoundingClientRect().width;

        // Lock scrolling to remove vertical scrollbar
        body.style.height = oldHeight;
        body.style.overflow = 'hidden';

        // Calculate scrollbar width
        scrollbarWidth = Math.abs(bodyWidth - body.getBoundingClientRect().width);

        // Release scrolling
        body.style.overflow = oldOverflow;
        window.scrollTo(0, scrollTop);
    }

    return scrollbarWidth;

};

/*
*   Add styles to a DOM node
*
 */
export const setStyle = (el, styles = {}) => {
    Object.assign(el.style, styles);
};


/*
*   Load an external stylesheet
*
 */
export const loadCss = (cssUrl, callback = null, media = null) => {
    const head = document.getElementsByTagName('head')[0];
    const body = document.body;
    const css = document.createElement('link');
    const img = document.createElement('img');

    css.href = cssUrl;
    css.type = 'text/css';
    css.media = media || 'screen,print';
    css.rel = 'stylesheet';

    head.appendChild(css);

    img.onerror = () => {
        body.removeChild(img);
        if (callback) callback();
    };

    body.appendChild(img);
    img.src = cssUrl;
};
